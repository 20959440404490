import {getUserPremiumLevel, isUserDesigner, isUserLoggedIn, isUserPremium} from '@Libraries/user.library';
import {updatePanelProperties, updatePanelSizeData} from '@Components/panel/panel-reducer';
import {isPremiumUser} from '@Utils/user.util';
import type {RGB, RGBA} from '@Utils/color.util';
import {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {readCookie, setCookie} from '@Utils/cookie.util';
import {COPY_STYLES_COOKIE, DESIGN_EDITOR_ONBOARDING_COOKIE} from '@Components/poster-editor/poster-editor.types';
import {GROWL_TYPE, showMessageGrowl} from '@Components/message-growl';
import {openMessageModal} from '@Modals/message-modal';
import {updateCopiedStylesState, updateSidebarState} from '@Components/poster-editor/poster-editor-reducer';
import {deviceType, isMobile, isTablet} from 'react-device-detect';
import {showSaveReminderNotification} from '@Components/save-reminder-notification/save-reminder-notification.helper';
import {useAppSelector} from '@/hooks';
import {EDITOR_AUTOSAVE_ONBOARDING_COOKIE} from '@Hooks/poster-editor/useEditorMobileOnboarding';
import {setCanvasCursor} from '@Utils/fabric.util';
import type {CopyableItemStylesAndProperties} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import {DEVICE_TYPE} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import Mousetrap from 'mousetrap';
import type {Canvas} from '@postermywall/fabricjs-2';
import {CURSOR_OVERLAY_CONTAINER_ID} from '@Components/poster-editor/components/cursor-overlay/cursor-overlay.types';
import {GA4EventName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import {PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';
import {POSTER_CUSTOM, POSTER_CUSTOM_PRINT} from '@Panels/resize-panel/resize-panel.types';
import {hasDatePassed2} from '@Utils/date.util';

export const undo = (): void => {
  void window.posterEditor?.whiteboard?.history.undo();
};

export const redo = (): void => {
  void window.posterEditor?.whiteboard?.history.redo();
};

export const onAddBrandAssetClickedHandler = async (openInNewTab = false): Promise<void> => {
  if (!isUserPremium()) {
    window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_BRANDS);
    return;
  }
  const url = window.PMW.util.site_url(`posters/mine#/brands`);

  if (openInNewTab) {
    window.open(url, '_blank');
    return;
  }

  await window.posterEditor?.whiteboard?.savePoster.save();
  window.location.href = url;
};

export const getColorsOnMyDesign = (): string[] => {
  return window.posterEditor?.whiteboard?.getCurrentPage().getColorsOnPage() ?? [];
};

export const pausePosterOnModalOpen = (): void => {
  void window.posterEditor?.whiteboard?.pause();
};

export const setOnboardingPopupsSeen = async (): Promise<void> => {
  if (window.PMW.getUserId() !== 'notloggedin') {
    await window.PMW.writeLocal('user/updateUserHasSeenOnboardingPopups');
  }

  setCookie(DESIGN_EDITOR_ONBOARDING_COOKIE, '1', 180);
};

export const setAutoSaveOnboardingCookie = (): void => {
  setCookie(EDITOR_AUTOSAVE_ONBOARDING_COOKIE, '1', 180);
};

export const showAutoSaveOnBoarding = (): boolean => {
  if (window.posterEditor?.whiteboard?.savePoster.isAutoSaveEnabled() === false) {
    return false;
  }
  const value = readCookie(EDITOR_AUTOSAVE_ONBOARDING_COOKIE);

  if (!value) {
    return true;
  }

  const hasSeenValue = Number(value);
  return !hasSeenValue;
};

export const onPosterPause = (): void => {
  window.posterEditor?.whiteboard?.pause().catch((e) => {
    console.error(e);
  });
};

export const onPosterPlay = (): void => {
  window.posterEditor?.whiteboard?.play().catch((e) => {
    console.error(e);
  });
};

export const onPosterStop = (): void => {
  void window.posterEditor?.whiteboard?.stop();
};

// TODO: THIS COMPONENT DOES NOT SUPPORT MULTI PAGES FOR NOW
export const onPosterSkipForward = (): void => {
  // TODO: Add logic to turn to next page.
};

export const getNumberOfAudioItemsInPoster = (): number => {
  const audioPlayist = useAppSelector((state) => {
    return state.posterEditor.posterObject?.audioClips;
  });

  if (audioPlayist) {
    return Object.keys(audioPlayist.audioItemsHashMap).length;
  }
  return 0;
};

export const showCopyStylesAsNewFeature = (): boolean => {
  return isUserLoggedIn() && !readCookie(COPY_STYLES_COOKIE);
};

export const disablePastingStyles = (): void => {
  const canvas = window.posterEditor?.whiteboard?.getCurrentPage().fabricCanvas as Canvas;
  window.PMW.redux.store.dispatch(updateCopiedStylesState(undefined));
  setCanvasCursor(canvas, 'default');
  canvas.setCursor('default');
  const cursorOverlay = document.getElementById(CURSOR_OVERLAY_CONTAINER_ID);
  cursorOverlay?.removeEventListener('click', disablePastingStyles);
};

export const onCopyStylesClicked = (): void => {
  const selectedItemDetails = window.posterEditor?.whiteboard?.getCurrentPage().getSelectedItems();
  const canvas = window.posterEditor?.whiteboard?.getCurrentPage().fabricCanvas as Canvas;
  const cursor = 'images/postermaker/copy-style-brush.png';

  if (selectedItemDetails?.length) {
    const styles: CopyableItemStylesAndProperties = selectedItemDetails[0].getCopyableStyles();
    window.PMW.redux.store.dispatch(updateCopiedStylesState(styles));
    canvas.on('mouse:down', disablePastingStyles);

    setCookie(COPY_STYLES_COOKIE, '1', 180);
    trackPosterBuilderGA4Events(GA4EventName.COPY_STYLES_CLICKED);

    setTimeout(() => {
      canvas.setCursor(`url("${window.PMW.util.asset_url(cursor)}") 0 16  , auto`);
      setCanvasCursor(canvas, `url("${window.PMW.util.asset_url(cursor)}") 0 16 , auto`);
    }, 10);

    const cursorOverlay = document.getElementById(CURSOR_OVERLAY_CONTAINER_ID);
    cursorOverlay?.addEventListener('click', disablePastingStyles);

    if (deviceType === DEVICE_TYPE.MOBILE) {
      showMessageGrowl({
        text: window.i18next.t('pmwjs_style_copied'),
        interval: 3000,
        type: GROWL_TYPE.NEUTRAL,
        showCloseIcon: false,
        icon: 'icon-copy-style-brush',
      });
    }

    if (deviceType !== DEVICE_TYPE.MOBILE) {
      Mousetrap.bind('escape', disablePastingStyles);
    }
  }
};
export const pasteStylesOnMouseDown = async (): Promise<void> => {
  const selectedItems = window.posterEditor?.whiteboard?.getCurrentPage().getSelectedItems();
  const {copiedStyles} = window.PMW.redux.store.getState().posterEditor;
  if (selectedItems && copiedStyles !== undefined) {
    try {
      await Promise.all(
        selectedItems.map(async (selectedItem) => {
          try {
            await selectedItem.pasteStyles(copiedStyles);
          } catch (e) {
            console.error(e);
          }
        })
      );
      if (deviceType === DEVICE_TYPE.MOBILE) {
        showMessageGrowl({
          text: window.i18next.t('pmwjs_style_pasted'),
          interval: 3000,
          type: GROWL_TYPE.NEUTRAL,
          showCloseIcon: false,
          icon: 'icon-paste-style-brush',
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
  window.PMW.redux.store.dispatch(updateCopiedStylesState(undefined));
  setCanvasCursor(window.posterEditor?.whiteboard?.getCurrentPage().fabricCanvas as Canvas, 'default');
};

export const deleteItemByID = (uid: string): void => {
  window.posterEditor?.whiteboard?.deleteItemById(uid);
};

export const toggleLockStateForSelectedItem = (): void => {
  const selectedItems = window.posterEditor?.whiteboard?.getCurrentPage().getSelectedItems();

  if (selectedItems?.length !== 1) {
    return;
  }

  void selectedItems[0].updateFromObject({
    lockMovement: !selectedItems[0].lockMovement,
  });
};

export const duplicateActiveItems = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  currentPage?.activeSelection?.onDuplicate();
};

export const removeImageSlideBackgroundFromId = (slideshowId: string, uid: string): void => {
  if (!isPremiumUser(getUserPremiumLevel())) {
    window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_BG_REMOVALS);
    return;
  }

  const slideshow = window.posterEditor?.whiteboard?.getItemForId(slideshowId);

  if (!slideshow?.isSlideshow()) {
    return;
  }

  const item = slideshow.slides.slidesHashMap[uid];

  if (!item?.isImageSlide()) {
    return;
  }

  if (item.removeBackground.isBackgroundRemoved || item.removeBackground.isBackgroundBeingRemoved) {
    return;
  }

  void item.removeBackground.removeBackground();
};

export const removeImageBackgroundFromId = (uid: string): void => {
  if (!isPremiumUser(getUserPremiumLevel())) {
    window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_BG_REMOVALS);
    return;
  }

  const item = window.posterEditor?.whiteboard?.getItemForId(uid);
  if (!item?.isImage()) {
    return;
  }

  if (item.removeBackground.isBackgroundRemoved || item.removeBackground.isBackgroundBeingRemoved) {
    return;
  }

  void item.removeBackground.removeBackground();
};

export const removeVideoBackgroundFromId = (uid: string): void => {
  if (!isPremiumUser(getUserPremiumLevel())) {
    window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_VIDEO_BG_REMOVALS);
    return;
  }

  const item = window.posterEditor?.whiteboard?.getItemForId(uid);
  if (!item?.isVideo()) {
    return;
  }

  if (item.removeBackground.isBackgroundRemoved || item.removeBackground.isBackgroundBeingRemoved) {
    return;
  }

  void item.removeBackground.removeBackground();
};

export const removeVideoSlideBackgroundFromId = (slideshowId: string, uid: string): void => {
  if (!isPremiumUser(getUserPremiumLevel())) {
    window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_VIDEO_BG_REMOVALS);
    return;
  }

  const slideshow = window.posterEditor?.whiteboard?.getItemForId(slideshowId);

  if (!slideshow?.isSlideshow()) {
    return;
  }

  const item = slideshow.slides.slidesHashMap[uid];

  if (!item?.isVideoSlide()) {
    return;
  }

  if (item.removeBackground.isBackgroundRemoved || item.removeBackground.isBackgroundBeingRemoved) {
    return;
  }

  void item.removeBackground.removeBackground();
};

export const updateOptionsPanelHeight = (panelId: string, height: string): void => {
  window.PMW.redux.store.dispatch(
    updatePanelSizeData({
      sizeDataId: panelId,
      sizeData: {
        height,
      },
    })
  );
};

export const updateOptionsPanelStickToBottom = (panelId: string, stickToBottom: boolean): void => {
  window.PMW.redux.store.dispatch(
    updatePanelProperties({
      panelId,
      stickToBottom,
    })
  );
};

export const updateOptionsPanelHasNoBorderRadius = (panelId: string, noBorderRadius: boolean): void => {
  window.PMW.redux.store.dispatch(
    updatePanelProperties({
      panelId,
      noBorderRadius,
    })
  );
};

export const updateOptionsPanelHasNoBorder = (panelId: string, noBorder: boolean): void => {
  window.PMW.redux.store.dispatch(
    updatePanelProperties({
      panelId,
      noBorder,
    })
  );
};

export const updateOptionsPanelMargin = (panelId: string, margin: number): void => {
  window.PMW.redux.store.dispatch(
    updatePanelProperties({
      panelId,
      margin,
    })
  );
};

export const updateOptionsPanelProperties = (panelId: string, hasSnapPoints: boolean): void => {
  window.PMW.redux.store.dispatch(
    updatePanelProperties({
      panelId,
      hasSnapPoints,
    })
  );
};

export const updateLastAddedTextColor = (color: RGBA): void => {
  if (window.posterEditor?.whiteboard) {
    window.posterEditor.whiteboard.lastAddedTextColor = color;
  }
};

export const updateLastDrawBrushColor = (color: RGBA): void => {
  if (window.posterEditor?.whiteboard) {
    window.posterEditor.whiteboard.drawing.lastDrawBrushColor = color;
  }
};

export const doesLayoutItemHasDualFonts = (type: LayoutTypes): boolean => {
  const layoutOptionsWithDualFonts = [
    LayoutTypes.BAND_LAYOUT_LEFT_ALIGN,
    LayoutTypes.BAND_LAYOUT_RIGHT_ALIGN,
    LayoutTypes.BAND_LAYOUT_CENTER_ALIGN,
    LayoutTypes.OFFSET_SPORTS_LAYOUT,
  ];

  return layoutOptionsWithDualFonts.includes(type);
};

export const openNewDesign = (): void => {
  if (isUserLoggedIn()) {
    void window.posterEditor?.whiteboard?.savePoster.save().then((): void => {
      window.open(window.PMW.util.site_url('posterbuilder'), '_blank');
    });
  } else {
    showSaveReminderNotification();
    window.open(window.PMW.util.site_url('posterbuilder'), '_blank');
  }
};

export const copyPoster = (): void => {
  void window.posterEditor?.whiteboard?.savePoster.save().then((): void => {
    const hashedID = window.posterEditor?.whiteboard?.hashedID;
    if (hashedID) {
      window.open(window.PMW.util.site_url(`posterbuilder/copy/${hashedID}`), '_blank');
    }
  });
};

export const onClickOpenADesign = (): void => {
  void window.posterEditor?.whiteboard?.savePoster.save().then((): void => {
    window.open(window.PMW.util.site_url('posters/mine'));
  });
};

export const shouldDisplaySubmitAsTemplateButton = (): boolean => {
  return isUserDesigner() && !window.posterEditor?.whiteboard?.isPosterTemplate() && !window.PMW.isEmbedded();
};

export const submitAsTemplateCallback = (): void => {
  void window.posterEditor?.whiteboard?.savePoster.save().then((): void => {
    const hashedID = window.posterEditor?.whiteboard?.hashedID;
    if (hashedID) {
      window.location.href = window.PMW.util.site_url(`sell/submit/${hashedID}`);
    }
  });
};

export const showEditorNotificationMessageGrowl = (text: string): void => {
  showMessageGrowl({
    text,
    interval: 2000,
    type: GROWL_TYPE.NEUTRAL,
    showCloseIcon: false,
  });
};

export const isEditorMobileVariant = (): boolean => {
  if (isTablet) {
    return false;
  }

  return isMobile;
};

export const openEditorPosterTools = (): void => {
  if (window.posterEditor?.whiteboard?.hashedID) {
    window.open(window.PMW.util.site_url(`posterbuilder/openPosterTools/${window.posterEditor.whiteboard.hashedID}`));
  } else {
    openMessageModal({
      text: 'No hash ID. New poster that has not been saved yet?',
    });
  }
};

export const isSidebarSmall = (): boolean => {
  return window.PMW.redux.store.getState().posterEditor?.isSidebarSmall ?? false;
};

export const updateSidebarOpenState = (newState = true): void => {
  window.PMW.redux.store.dispatch(updateSidebarState(newState));
};

export const doesPosterHaveAudios = (): boolean => {
  const audioHash = window.posterEditor?.whiteboard?.audioClips.audioItemsHashMap;
  return !(!audioHash || Object.keys(audioHash).length === 0);
};

export const logoutUserFromEmbeddedEditor = async (): Promise<void> => {
  window.PMW.showLoading('embeddedEditorLogout', {text: window.i18next.t('pmwjs_logging_out')});
  try {
    await window.PMW.writeLocal('authenticate/asyncLogout');
    window.location.reload();
  } catch (e) {
    console.error(e);
  } finally {
    window.PMW.hideLoading('embeddedEditorLogout');
  }
};

const EDITOR_TEMPLATES_POPUP_COOKIE = 'pmwetp';
const EDITOR_TEMPLATES_POPUP_PULSATION_DEADLINE = '2025-05-13';
export const showTemplatesPopupPulsatingDot = (): boolean => {
  if (!isUserLoggedIn()) {
    return false;
  }
  const targetDate = new Date(EDITOR_TEMPLATES_POPUP_PULSATION_DEADLINE);
  if (hasDatePassed2(targetDate)) {
    return false;
  }
  const value = readCookie(EDITOR_TEMPLATES_POPUP_COOKIE);

  if (!value) {
    return true;
  }

  const hasSeenValue = Number(value);
  return !hasSeenValue;
};

export const toggleTemplatesPopupOnBoardingToSeen = (): void => {
  setCookie(EDITOR_TEMPLATES_POPUP_COOKIE, '1', 180);
};

const TIMELINE_ONBOARDING_COOKIE = 'pmwto';
const TIMELINE_ONBOARDING_DEADLINE = '2025-03-29';
export const showTimelineOnBoarding = (): boolean => {
  if (!isUserLoggedIn()) {
    return false;
  }

  const targetDate = new Date(TIMELINE_ONBOARDING_DEADLINE);
  if (hasDatePassed2(targetDate)) {
    return false;
  }

  const value = readCookie(TIMELINE_ONBOARDING_COOKIE);

  if (!value) {
    return true;
  }

  const hasSeenValue = Number(value);
  return !hasSeenValue;
};

export const toggleTimelineOnBoardingToSeen = (): void => {
  setCookie(TIMELINE_ONBOARDING_COOKIE, '1', 180);
};

export const isInReviewOrGalleryTemplate = (): boolean => {
  return !!window.posterEditor?.whiteboard?.isGalleryTemplate() || !!window.posterEditor?.config?.isInReview;
};

export const isTemplatesPopupHidden = (): boolean => {
  return window.posterEditor?.whiteboard?.mode.details.type === PosterModeType.EDIT && isInReviewOrGalleryTemplate();
};

export const isCustomPosterType = (): boolean => {
  const posterType = window.posterEditor?.whiteboard?.type;
  return posterType?.name === POSTER_CUSTOM || posterType?.name === POSTER_CUSTOM_PRINT;
};
